body {
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
.card-body > .card-title {
    font-family: 'Lobster', cursive;
}

.card-title {
    font-weight: 500;
    font-size: 1.25rem;
}

h2 {
    color: #3046c5;
}

.card-img-overlay .card-title {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
}

.row-content {
    padding: 50px 0;
    border-bottom: 1px ridge;
    min-height: 200px;
}

.site-footer {
    background-image: linear-gradient(#cec8ff, white);
    padding: 20px 0;
    margin-top: 2rem;
}

.navbar-brand {
    max-width: 4rem;
    margin-right: 2rem;
}

.navbar-brand img {
    width: 100%;
    height: 100%;
    margin-right: 2rem;
    max-width: 4rem;
}

.navbar-dark {
    background-color: #901cad;
    background-image: linear-gradient(to right, #5637dd, #3046c5, #510e5a);
    margin-bottom: 2rem;
    min-height: 8rem;
}

.page-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: 300ms ease-out;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transition: 300ms ease-in;
}
